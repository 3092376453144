"use client";
import React, { useState } from "react";
import { Button, Nav, Navbar, Offcanvas, Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LangSelector from "../languages/LangSelector";

function Header({ isSticky }) {
  const [_] = useTranslation("global");
  const [showSidebar, setShowSidebar] = useState(false);
  const { lang ,slug} = useParams();
  const navigate = useNavigate();
  const handleSidebarClose = () => setShowSidebar(false);
  const handleSidebarOpen = () => setShowSidebar(true);
  const logo =
    "https://ecombo.sgp1.cdn.digitaloceanspaces.com/assets/Logo_Ecombo 1.png";

  return (
    <Navbar
      dir={lang === "en" ? "ltr" : "rtl"}
      expand="lg"
      bg="white"
      fixed={isSticky ? "top" : undefined}
      className={`shadow-lg transition-all duration-300 ${
        isSticky ? "z-50" : ""
      }`}
    >
      <Container>
        <Navbar.Brand className="d-flex align-items-center ">
          <img
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
            src={logo}
            alt="ايكومبو"
            width={150}
            height={44}
          />
            <LangSelector slug={slug} lang={lang} />
            </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="navbar-offcanvas"
          onClick={handleSidebarOpen}
        />

        <Navbar.Collapse id="navbar-offcanvas" className="justify-content-end">
          <Nav>
            <Nav.Link href="#about" className="text-textBase text-xl">
              {_("static_home.header.about")}
            </Nav.Link>
            <Nav.Link href="#why" className="text-textBase text-xl">
              {_("static_home.header.why")}
            </Nav.Link>
            <Nav.Link href="#where" className="text-textBase text-xl">
              {_("static_home.header.where")}
            </Nav.Link>
            <Nav.Link
              href="#marketerSolution"
              className="text-textBase text-xl"
            >
              {_("static_home.header.marketerSolution")}
            </Nav.Link>
            <Nav.Link
              href="#supplierSolution"
              className="text-textBase text-xl"
            >
              {_("static_home.header.supplierSolution")}
            </Nav.Link>
            <Nav.Link
              href="#common-questions"
              className="text-textBase text-xl"
            >
              {_("static_home.header.commonQuestions")}
            </Nav.Link>{" "}
            <Nav.Link href="#">
              {/* <Button
                variant="secondary"
                onClick={() => navigate(`/${lang}/auth/register/choice`)}
                className="mx-1 w-100 ecombo-btn-secondary"
              >
                {_("static_home.header.register")}
              </Button> */}
            </Nav.Link>
            <Nav.Link href="#">
              <Button
                variant="secondary"
                onClick={() => navigate(`/${lang}/auth/login`)}
                className="mx-1 w-100 ecombo-btn-secondary"
              >
                {_("static_home.header.login")}
              </Button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>

      {/* <Offcanvas show={showSidebar} onHide={handleSidebarClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>القائمة</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            <Nav.Link   href="#about" className="text-textBase">
              عن ايكومبو
            </Nav.Link>
            <Nav.Link   href="#why" className="text-textBase">
              لماذا ايكومبو
            </Nav.Link>
            <Nav.Link   href="#how" className="text-textBase">
              كيف تبدأ
            </Nav.Link>
            <Nav.Link   href="#common-questions" className="text-textBase">
              الاسئلة الشائعة
            </Nav.Link>
            <Nav.Link   href="#">
              <Button variant="secondary" className="w-100">
                تسجيل دخول
              </Button>
            </Nav.Link>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas> */}
    </Navbar>
  );
}

export default Header;
