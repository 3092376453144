import React, { useState } from "react";
import logo from "../../imgs/Logo_Ecombo 1.png";
import { Form, Button, Alert } from "react-bootstrap";
import { Link, useParams, useNavigate, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LangSelector from "../languages/LangSelector";
import AxiosController from "../authentication/AxiosController";
import Loader from "../loader/Loader";
import { Helmet } from "react-helmet";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [loader, setLoader] = useState(false);
  const [authErr, setAuthErr] = useState("");
  const token = localStorage.getItem("token");
  const [_] = useTranslation("global");
  const { lang } = useParams();
  const navigate = useNavigate();
  if (token) {
    navigate(`/ae/${lang}`);
  }
  const loginAction = async () => {
    setLoader(true);
    AxiosController.post(`api/${lang}/auth/login`, { email, password })
      .then((res) => {
        const token = res.data.token; // Assuming the token is available in the response data

        localStorage.setItem("token", token); // Store the token in local storage
        localStorage.setItem("role", res.data.user.role); // Store the token in local storage
        localStorage.setItem("employee_type", res.data.user.employee_type); // Store the token in local storage
        localStorage.setItem("loginTime", new Date().getTime()); // Store the login timestamp in local storage
        setTimeout(() => {
          localStorage.clear();
        }, 3600000);
        navigate(`/${res.data.slug}/${lang}/`);
      })

      .catch((err) => {
        setEmailErr(
          err.response.data.error.email ? err.response.data.error.email : ""
        );
        setPasswordErr(
          err.response.data.error.email ? err.response.data.error.password : ""
        );
        setAuthErr(
          err.response.data.error.auth ? err.response.data.error.auth : ""
        );
        setLoader(false);
      });
  };
  return (
    <div
      className="   inputs-data d-flex align-items-center px-5 justify-content-center flex-grow-1 position-relative overflow-hidden"
      style={{ backgroundColor: "white" }}
    >
      <Helmet>
        <title>{_("registeration.signin")}</title>
      </Helmet>
      <Loader active={loader} />
      <div className="data-entry-container" style={{ width: "100%" }}>
        <div className="d-flex justify-content-between flex-column flex-md-row-reverse">
          <NavLink to={`/ae/${lang}/`}>
            <img src={logo} width="230px" alt="" />
          </NavLink>
          <LangSelector lang={lang} />
        </div>
        <div className="header d-flex justify-content-end">
          <h3 style={{ color: "rgba(243, 168, 55, 1)", fontSize: "1.6rem" }}>
            {_("registeration.welcoming")}
          </h3>
        </div>
        <Form
          className="d-flex flex-column gap-2"
          dir={lang === "ar" ? "rtl" : "ltr"}
          onSubmit={(e) => {
            e.preventDefault();
            loginAction();
          }}
        >
          <Form.Group className="d-flex flex-column gap-2">
            <Form.Label>{_("registeration.email")}</Form.Label>
            <Form.Control
              type="email"
              className="p-2"
              placeholder={`${_("registeration.enter_email")}`}
              name="user_email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailErr ? <Alert variant="danger">{emailErr}</Alert> : ""}
          </Form.Group>
          <Form.Group className="d-flex flex-column gap-2">
            <Form.Label>{_("registeration.enter_password")}</Form.Label>

            <Form.Control
              type="password"
              className="p-2"
              placeholder={`${_("registeration.enter_password")}`}
              name="user_password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {passwordErr ? <Alert variant="danger">{passwordErr}</Alert> : ""}
            {authErr ? <Alert variant="danger">{authErr}</Alert> : ""}
          </Form.Group>
          <div className="forget-password d-flex">
            <NavLink to={`/${lang}/auth/forgot-password`}>
              {_("registeration.forget")}
            </NavLink>
          </div>
          <input
            className="btn"
            style={{
              color: "white",
              backgroundColor: "rgba(89, 198, 201, 1)",
              border: "none",
            }}
            type="submit"
            value={_("registeration.signin")}
          />
          {/* <p style={{ color: "rgba(214, 214, 214, 1)" }}>
            {_("registeration.donthave")}
            <Link to="../register/choice">{_("registeration.createone")}</Link>
          </p> */}
        </Form>
      </div>
    </div>
  );
};

export default Login;
