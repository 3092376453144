import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import AxiosController from "../../authentication/AxiosController";
import IconsGen from "../../icons_generator/IconsGen";
import CreateAndUpdateProductDiscounts from "../../modals/CreateAndUpdateProductDiscounts";

const ProductDiscounts = () => {
  const { slug, lang, productId } = useParams();
  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState(null);
  const [id, setId] = useState('');
  const [allData, setAllData] = useState([]);
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [modalMethod, setModalMethod] = useState("add"); // State for modal method
  const [selectedProductId, setSelectedProductId] = useState(null); // State for selected product ID

  // Fetch product discounts
  const get_product_discounts = () => {
    setLoading(true);
    AxiosController.get(
      `/apiv2/${slug}/${lang}/product-discounts/product/${productId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        setAllData(res?.data || []);
      })
      .catch(() => {
        setAllData([]);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    get_product_discounts();
  }, []);

  // Handle add button click
  const handleAddClick = () => {
    setModalMethod("add");
    setSelectedProductId(null);
    setShowModal(true);
  };

  // Handle edit button click
  const handleEditClick = (id) => {
    const selectedProduct = allData.find((product) => product.id === id);
    setId(id);
    setProductData(selectedProduct);
    setModalMethod("edit");
    setSelectedProductId(id);
    setShowModal(true);
  };

 
  const handleDeleteClick = (id) => {
    if (window.confirm("Are you sure you want to delete this discount?")) {
      AxiosController.delete(
        `/apiv2/${slug}/${lang}/product-discounts/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
        .then(() => {
       
          get_product_discounts();
        })
        .catch((error) => {
          console.error("Error deleting discount:", error);
        });
    }
  };

  // Handle modal close
  const handleModalClose = () => {
    setShowModal(false);
    setProductData('');
  };

  // Handle modal success
  const handleModalSuccess = () => {
    get_product_discounts();
    setShowModal(false);
  };

  return (
    <div className="flex-grow-1 stats-component" dir={box_head_dir}>
      <Helmet>
        <title>{_("panel.productDiscounts")}</title>
      </Helmet>
      <Button
        className="fs-5 mt-3 px-5 w-25 mb-4"
        style={{
          backgroundColor: "rgb(89, 198, 201)",
          border: "none",
          borderRadius: "7px",
        }}
        onClick={handleAddClick}
      >
        {_("panel.add")}
      </Button>
      <div className=" d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
        <h3 className="fs-2 mb-4" style={{ color: "rgba(89, 198, 201, 1)" }}>
          {_("panel.productDiscounts")}
        </h3>
      </div>
      <div className="  my-3 d-flex flex-column gap-2 px-2 position-relative">
        <div className="row">
          <div
            style={{ borderRadius: "40px" }}
            className={`table-responsive border my-3 d-flex ${
              lang === "en" ? "flex-row" : "flex-row-reversed"
            } align-items-center`}
          >
            <Table responsive className="table table-borderless">
              <thead>
                <tr className="text-center">
                  <th>#</th>
                  <th>{_("panel.quantity")}</th>
                  <th>{_("panel.sys_commission")}</th>
                  <th>{_("panel.seller_commission")}</th>
                  <th>{_("panel.edit")}</th>
                  <th>{_("panel.delete")}</th>
                </tr>
              </thead>
              <tbody>
                {allData.length > 0 ? (
                  [...allData]
                    .sort((a, b) => a.quantity - b.quantity)
                    .map((product, index) => (
                      <tr key={product.product_id}>
                        <td>{index + 1}</td>
                        <td>{product.quantity}</td>
                        <td>{product.sys_modified_commission || null}</td>
                        <td>{product.seller_modified_commission || null}</td>
                        <td onClick={() => handleEditClick(product.id)}>
                          <IconsGen icon_name={"edit"} />
                        </td>
                        <td onClick={() => handleDeleteClick(product.id)}>
                          <IconsGen icon_name={"delete"} />
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">
                      No Data to show
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <CreateAndUpdateProductDiscounts
        method={modalMethod}
        show={showModal}
        productData={productData}
        handleModalClose={handleModalClose}
        productId={productId}
        selectedProductId={selectedProductId}
        handleModalSuccess={handleModalSuccess}
      />
    </div>
  );
};

export default ProductDiscounts;