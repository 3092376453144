import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import { useParams } from "react-router-dom";
import AxiosController from "../../../authentication/AxiosController";

export default function LineCharts() {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const token = localStorage.getItem("token");
  const { slug, lang } = useParams();
  
  const setchart = async () => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data = {
      labels: ["January", "February", "March", "April", "May", "June", "July"],
      datasets: [
        {
          label:"",
          data: [],
          fill: false,
          borderColor: 'rgb(243, 168, 55)',
          tension: 0.4,
        },
      ],
    };
    await AxiosController.get(`api/${slug}/${lang}/admin/month-orders-days`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res?.data?.month_orders_days) {
          data.labels = res.data?.month_orders_days.map((record) => record?.order_day)
          data.datasets[0].data = res.data?.month_orders_days.map((record) => record?.count);
        }
      })
      .catch((e) => {});
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }
  useEffect(  () => {
    setchart();
  }, []);

  return (
    <div className="card border-0">
        <h2 className=" text-center mb-2">  عدد الطلبات اليومي لمدة شهر</h2>
      <Chart
        type="line"
        data={chartData}
        options={chartOptions}
        style={{ height: "300px", width: "100%" }}
      />
    </div>
  );
}
