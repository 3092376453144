import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import "primereact/resources/primereact.min.css"; // Import necessary PrimeReact styles
import { useParams } from "react-router-dom";
import AxiosController from "../../../authentication/AxiosController";

export default function Top() {
  const [dateRange, setDateRange] = useState([
    new Date(2020, 7, 9),
    new Date(2025, 7, 9),
  ]);
  const token = localStorage.getItem("token");
  const { slug, lang } = useParams();
  const [topMarketers, setTopMarketers] = useState([]);
  const [topProducts, setTopProducts] = useState([]);




  const customerNameTemplate = (rowData) => {
    return (
      <div className="d-flex align-items-center">
        <img
          src={rowData.avatar}
          alt={rowData.name}
          className="rounded-circle ms-2"
          style={{ width: "45px", height: "45px" }}
        />
        <span>{rowData.name}</span>
      </div>
    );
  };

  const productNameTemplate = (rowData) => {
    return (
      <div className="d-flex align-items-center">
        <img
          src={rowData.image}
          alt={rowData.name}
          className="ms-2 rounded-circle"
          style={{ width: "45px", height: "45px" }}
        />
        <span>{rowData.name}</span>
      </div>
    );
  };

  const ratingTemplate = (rowData) => {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <span className="text-warning">{rowData.rating}</span>
      </div>
    );
  };
  const top_orders_marketers = () => {
    AxiosController.get(`api/${slug}/${lang}/admin/top-orders-marketers`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res?.data?.top_marketers) {
          setTopMarketers(res?.data?.top_marketers.map((record) => {
            return {
              id: record.marketer_id,
              name: `#${record?.marketer_id} ` + record?.marketer?.first_name + " " + record?.marketer?.last_name,
              orders:`${record?.count} اوردر`,
              avatar:record?.marketer?.photo
            };
          }));
        }
      })
      .catch((e) => {});
  };
  const top_orders_products = () => {
    AxiosController.get(`api/${slug}/${lang}/admin/top-orders-products`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {

        if (res?.data?.top_products) {
          setTopProducts(res?.data?.top_products.map((record) => {
            return {
              id: record.product_id,
              name: `#${record?.product_id} ` + record?.product?.title,
              rating:`${record?.count} اوردر`,
              image:record?.product?.images?.[0]?.img_url
            };
          }));
        }
      })
      .catch((e) => {});
  };
  useEffect(() => {
    top_orders_marketers();
    top_orders_products();
  }, []);
  return (
    <Container fluid className="py-3" style={{ direction: "rtl" }}>
      {/* <Row className="mb-3">
        <Col xs={3}>
          <Calendar
            value={dateRange}
            onChange={(e) => setDateRange(e.value)}
            selectionMode="range"
            readOnlyInput
            className="w-100 px-2 py-1 top-calendar-input"
          />
        </Col>
      </Row> */}
      <Row>
        <Col md={6}>
          <Card className="border-0 shadow">
            <Card.Header className="bg-white border-0">
              <h5 className="mb-0 text-center">أعلى المسوقين طلبا في الشهر</h5>
            </Card.Header>
            <Card.Body>
              <DataTable value={topMarketers} className="datatable-sm">
                <Column
                  field="name"
                  header="المسوق"
                  body={customerNameTemplate}
                  key="name"
                />
                <Column field="orders" header="الاوردرات" key="orders" />
              </DataTable>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="border-0 shadow">
            <Card.Header className="bg-white border-0">
              <h5 className="mb-0 text-center">أعلى المنتجات طلبا في الشهر</h5>
            </Card.Header>
            <Card.Body>
              <DataTable value={topProducts} className="datatable-sm">
                <Column
                  field="name"
                  header="المنتج"
                  body={productNameTemplate}
                  key="name"
                />
                <Column
                  field="rating"
                  header="الاوردرات"
                  body={ratingTemplate}
                  key="rating"
                />
              </DataTable>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
