import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const AutomaticLogout = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("token");

  useEffect(() => {
    const lastActivity = localStorage.getItem("loginTime");
    const currentTime = new Date().getTime();

     const allowedRoutes = ["/auth/register/marketer","/auth/register/seller", "auth/password/reset"];
    
    if (allowedRoutes.some((route) => location.pathname.includes(route))) {
      return;
    }

    if (!token || (lastActivity && currentTime - parseInt(lastActivity, 10) > 43200000)) {
      localStorage.clear();
      navigate("/", { replace: true });
    }
  }, [token]);  

  return <>{children}</>;
};

export default AutomaticLogout;
