import "./Home.css";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import {  useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AxiosController from "../../components/authentication/AxiosController";
import Loader from "../../components/loader/Loader";
import HomeCarousel from "../../components/home/HomeCarousel";
import ProductsSlider from "../../components/home/ProductsSlider"; 
import NewArrival from "./NewArrival";
import MostSelling from "./MostSelling"; 
import StaticProducts from "./StaticProducts";
import Rewards from "./Rewards";
import { Offers } from "./Offers";

const Home = () => {
  
  const [_] = useTranslation("global");
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const employee_type = localStorage.getItem("employee_type");
  const [loading, handleLoader] = useState(true);
  const [sliderImgs, handleSliderImgs] = useState([]);
  const [productsRows, handleProductsRows] = useState([]);
  const [altName, handleAltName] = useState("");
  const [newArrivalRow, handleNewArrivalRow] = useState([]);
  const [mostSellingRow, handleMostSellingRow] = useState([]); 
  
  useEffect(() => {
    if (
      role === "admin" ||
      role === "affiliate" ||
      employee_type === "operation employee"
    ) {
      AxiosController.get(`api/${slug}/${lang}/product/new-arrivals/0`)
        .then((res) => {
          handleNewArrivalRow(res?.data?.data);
        })
        .catch((e) => {});
      AxiosController.get(`api/${slug}/${lang}/product/most-selling/1`)
        .then((res) => {
          handleMostSellingRow(res?.data?.data);
        })
        .catch((e) => {});
    }

    if (token) {
      AxiosController.get(`api/${slug}/${lang}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          handleSliderImgs(res.data.banners);
          handleProductsRows(res.data.products_grouped);
          handleAltName(res.data.alt_name);
          handleLoader(false);
        })
        .catch((e) => {    handleLoader(false);});
    } else {
      AxiosController.get(`api/${slug}/${lang}/public`)
        .then((res) => {
          handleSliderImgs(res.data.banners);
          handleProductsRows(res.data.products_grouped);
          handleAltName(res.data.alt_name);
          handleLoader(false);
        })
        .catch((e) => {    handleLoader(false);});
    }
  }, [slug, lang, token]);

  return (
    <Container
      style={{ position: "relative", minHeight: "400px" , overflowX: "hidden" }}
      className="home-container  "
    >
       
      <Loader active={loading} />
      <HomeCarousel imgs={sliderImgs} /> 
     {  role == "affiliate" && <Rewards/> }
      {(role == "admin" ||
        role == "affiliate" ||
        !token  ||
        employee_type == "operation employee") && (
        <> 
        <Offers/>
        <NewArrival/>
        <MostSelling/>
        
      
        </>
      )}
      {Object.keys(productsRows).map((key) => {
        if (productsRows[key].length > 0) {
            console.log("=====================================")
              console.log(productsRows)
              console.log("=====================================")
          return (
            <div key={key}>
              <ProductsSlider row={productsRows[key]} altName={altName} />
            </div>
          );
        }
        return null;
      })}
    </Container>
  );
};

export default Home;
