import React from "react";
import { useTranslation } from "react-i18next";

const TableLabels = () => {
  const { t } = useTranslation("global"); // Use the translation function

  // Define a fixed set of labels
  const labels = [t("panel.date"), t("panel.subject"), t("panel.content")];

  return (
    <tr>
      {labels.map((label, index) => (
        <th key={index}>{label}</th>
      ))}
    </tr>
  );
};

export default TableLabels;
