export default {
  CANCELLED: "Canceled",
  RETUERNED: "Returned",
  CASH_DELIVERY: "Cash Delivery",
  DELIVERED: "Delivered",
  PENDING_CANCEL: "Pending Cancel",
  SHIPPED: "Shipped",
  PENDING_RETURN: "Pending Return",
  WAITING: "Waiting",
  READY_FOR_SHIPPING: "Ready For Shipping",
  CALL_ATTEMPT: "Call Attempt",
  CONFIRMED: "Confirmed",
  PENDING: "Pending",
  NOT_CONFIRMED: "Not Confirmed",
  SETTLED: "Settled",
};
